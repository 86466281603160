import React from "react"

const InstallRight = props => {
  return (
    props.version && props.versions.length > 0 ? 
      <div>
        <div className="rightCaption">Install</div>
        <div><pre><code>{`livepm install ` + props.package.name + (props.version.version === props.versions[0].version ? `` : ` ` + props.version.version)}</code></pre></div>
      </div>
      :
      <React.Fragment />
  )
}

export default InstallRight
