import React from "react"

import PackageInfo from "../components/package_info"
import Readme from "../components/readme"

const PackageLeft = props => {
  let returnDiv = props.package ? (
    <React.Fragment>
      <PackageInfo
        package={props.package}
        version={props.version}
        versions={props.versions}
        loggeduser={props.loggeduser}
        packagePriviledges={props.packagePriviledges}
      />
      <Readme package={props.package} version={props.version} />
    </React.Fragment>
  ) : (
      <React.Fragment />
    )

  return <React.Fragment>{returnDiv}</React.Fragment>
}

export default PackageLeft
