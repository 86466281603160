import React, { Component } from "react"
import TitleLeft from "./title_left"

import { PostTeam } from "../scripts/api"
import { getFormData } from "../scripts/misc"

import { Redirect } from "react-router"

import $ from "jquery"

class NewTeamLeft extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    redirectID: null,
    formError: null
  };

  // handleChange(event) {
  //   TODO cover bad input
  // }

  async submitTeam(formData) {
    let response = []
    try {
      response = await PostTeam(formData)
      this.setState({ redirectID: response.name })
    } catch (e) {
      $(`button`)[0].disabled = false
      this.setState({ formError: e.response.data.message })
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    $(`button`)[0].disabled = true
    // const formData = new FormData(event.target);
    const formData = getFormData($(event.target))

    const sendData = {
      name: formData[`name`],
      description: formData[`description`],
      organization: this.props.organization._id
    };

    this.submitTeam(sendData);
  }
  render() {
    return (
      <React.Fragment>
        {this.state.redirectID !== null ? (
          <Redirect to={`/packages/teams/` + this.props.organization.name} />
        ) : (
            <React.Fragment />
          )}
        <form className="form-horizontal" onSubmit={this.handleSubmit}>
          <TitleLeft title={`New Team`} />
          <div className={this.state.formError ? `form-error` : `d-none`}>
            {this.state.formError}
          </div>
          <div className="form-group">
            <div className="row">
              <label htmlFor="forName" className="col-sm-3 control-label">
                Name
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="forName"
                  placeholder="Enter Team Name"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <label
                htmlFor="forDescription"
                className="col-sm-3 control-label"
              >
                Description
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  name="description"
                  className="form-control"
                  id="forDescription"
                  placeholder="Team Description"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="button-wrapper text-center">
              <button type="submit" className="btn">
                Create Team
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

export default NewTeamLeft
