
const PackagePrivilegesTemplate = {
    canDelete: false,
    canWrite: false,
    canRelease: false
}

const OrganizationPrivilegesTemplate = {
    canDelete: false,
    canUpdate: false,
    canAddTeam: false,
    canUpdateTeam: false,
    canRemoveTeam: false,
    canAddPackage: false,
    canAssignPackage: false,
    canRemovePackage: false,
    canInviteUser: false,
    canRemoveUser: false,
    canPromoteUser: false
}

const UserPrivilegesTemplate = {
    canCreatePackage: false
}

class Privileges {
    constructor(template) {
        Object.keys(template).forEach(function (key) {
            this[key] = template[key]
        }.bind(this));
    }

    promote(privileges) {
        privileges.forEach(function (e) {
            if (!this.hasOwnProperty(e))
                throw new Error("Privileges property not found: " + e)

            this[e] = true
        }.bind(this))
        return this;
    }

    demote(privileges) {
        privileges.forEach(function (e) {
            if (!this.hasOwnProperty(e))
                throw new Error("Privileges property not found: " + e)

            this[e] = false
        }.bind(this))
        return this;
    }
}

export {
    PackagePrivilegesTemplate,
    OrganizationPrivilegesTemplate,
    UserPrivilegesTemplate,
    Privileges
}
