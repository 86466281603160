import React, { Component } from "react"

import { GetOrganizationByName, CurrentUser } from "../scripts/api"

import PackageSearch from "./package_search"
import NewTeamLeft from "./new_team_left"
import OrganizationRight from "./organization_right"

class NewTeam extends Component {
  state = {
    currentUser: undefined,
    organization: null
  };

  async fetchData() {
    let organization = await GetOrganizationByName(this.props.match.params.name)
    let user = await CurrentUser()
    this.setState({ organization: organization, currentUser: user })
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    return (
      <React.Fragment>
        <PackageSearch user={this.state.currentUser} />
        <div className="row">
          <div className="col-sm-8">
            <NewTeamLeft organization={this.state.organization} />
          </div>
          <div className="col-sm-4">
            {this.state.organization ? <OrganizationRight organization={this.state.organization} /> : <React.Fragment />}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default NewTeam
