import React, { Component } from "react"

import "../css/custom_checkbox.css"

class LoginForm extends Component {
  inputChangeHandler(event) {
    console.log(event.target.value)
  }

  render() {
    return (
      //   <React.Fragment/>
      <form className="loginForm form-horizontal">
        <div className="form-group">
          <div className="row">
            <label htmlFor="formEmail" className="col-sm-2 control-label">
              Email Address
            </label>
            <div className="col-sm-10">
              <input
                type="email"
                className="form-control"
                id="formEmail"
                placeholder="Enter Email Address"
                onChange={e => this.inputChangeHandler(e)}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <label
              htmlFor="formPassword"
              className="col-sm-2 control-label"
            >
              Password
            </label>
            <div className="col-sm-10">
              <input
                type="password"
                className="form-control"
                id="formPassword"
                placeholder="Enter Password"
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="offset-2 col-sm-10">
              <div className="customCheckbox">
                <input
                  type="checkbox"
                  value="None"
                  id="customCheckbox"
                  name="check"
                />
                <label htmlFor="customCheckbox">
                  {` `}
                  <div className="rememberMe">Remember me</div>
                  {` `}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="dontHaveAcc offset-2 col-sm-10">
            <span>Don&apos;t have an account?</span>
            <a href="#">Register</a>
            <a href="#">Forgot password?</a>
          </div>
        </div>
        <div className="form-group">
          <div className="button-wrapper text-center">
            <button type="submit" className="btn">
              Login
            </button>
          </div>
        </div>
      </form>
    )
  }
}

export default LoginForm
