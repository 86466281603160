import React, { Component } from "react"

import PackageSearch from "./package_search"
import NewVersionLeft from "./new_version_left"
import MenuRight from "./menu_right"
import OrganizationsRight from "./organizations_right"
import IconRight from "./icon_right"
import Page404 from "../components/page_404"

import { CurrentUser, GetPackageByName } from "../scripts/api"

class NewVersion extends Component {
  state = {
    currentUser: undefined,
    package: null,
    notFound: null
  };

  async fetchData() {
    let user = await CurrentUser()

    let packageName = this.props.match.params.name
    let pck = null
    try {
      pck = await GetPackageByName(packageName)
      this.setState({ currentUser: user, package: pck })
    } catch (e) {
      this.setState({notFound: true})
      return
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    if(this.state.notFound)
      return (
        <Page404></Page404>
      )

    return (
      <React.Fragment>
        <PackageSearch user={this.state.currentUser} />
        <div className="row">
          <div className="col-sm-8">
            <NewVersionLeft
              package={this.state.package}
            />
          </div>
          <div className="col-sm-4">
            <IconRight user={this.state.currentUser} />
            <MenuRight user={this.state.currentUser} />
            <OrganizationsRight />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default NewVersion
