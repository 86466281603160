import React, { Component } from "react"
import Select from "react-select"


import {
    GetReleaseReferences
} from "../scripts/api"

class ReferencesRight extends Component {

    selectStyle = {
        control: base => ({
            ...base,
            border: `0 !important`,
            boxShadow: `0 !important`,
            backgroundColor: `#111`,
            color: `#fff !important`,
            "&:hover": {
                border: `0 !important`
            }
        }),
        option: (styles, { _, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: `#111`,
            "&:hover": {
                color: `#fff`,
                backgroundColor: `#191919`
            },
            color: isSelected || isFocused ? `#fff` : `#fff`,
            cursor: isDisabled ? `not-allowed` : `default`,
            borderRadius: 8
        }),
        menu: base => ({
            ...base,
            backgroundColor: `#111`,
            padding: 4,
            borderRadius: 6
        }),
        singleValue: base => ({
            ...base,
            color: `#fff`
        })
    };

    state = {
        dependencies: [],
        dependents: []
    };

    selection = [];

    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)


        this.selection = this.props.version.releases.map(rl => {
            return {
                label: this.props.version.version + ' ' + rl.release, value: rl._id
            }
        })
    }

    async fetchData(id) {
        let packageVersion = await GetReleaseReferences(id)
        this.setState({
            dependencies: packageVersion.dependencies,
            dependents: packageVersion.dependents
        })
    }

    handleChange(event) {
        this.fetchData(event.value)
    }

    dependencies() {
        return (
            this.state.dependencies.map(d => {
                return (
                    <li><a href={`/package/${d.package.name}/${d.version}`}>{d.package.name} {d.version}</a></li>
                )
            })
        )
    }

    dependents() {
        return (
            this.state.dependents.map(d => {
                return (
                    <li><a href={`/package/${d.package.name}/${d.version}`}>{d.package.name} {d.version}</a></li>
                )
            })
        )
    }

    render() {
        return (
            <div>
                <div className="rightCaption">References</div>
                <Select
                    placeholder="Select release"
                    options={this.selection}
                    onChange={this.handleChange}
                    styles={this.selectStyle}
                />

                {this.state.dependencies.length > 0 ? <h4>Dependencies</h4> : <React.Fragment />}

                <ul>
                    {this.dependencies()}
                </ul>

                {this.state.dependents.length > 0 ? <h4>Dependents</h4> : <React.Fragment />}

                <ul>
                    {this.dependents()}
                </ul>
            </div>
        )
    }
}

export default ReferencesRight
