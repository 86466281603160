import React from "react"
import {getAvatarInfo } from "../scripts/misc"

const IconRight = props => {
  let { backgroundImage, backgroundColor, renderText } = getAvatarInfo(props.user)

  return (
    <React.Fragment>
      <div
        className="organizationIcon"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundColor: backgroundColor
        }}
      >
        {renderText}
      </div>
      <div className="organizationName">
        {props && props.user ? props.user.username : ``}
      </div>
    </React.Fragment>
  )
}

export default IconRight
