import React, { Component } from "react"
import { GetReadme } from "../scripts/api"

import marked from 'marked';

class Readme extends Component {

  componentDidMount() {
    this.fetchData()
  }

  state = {
    readmeContents: { __html: '' }
  };

  async fetchData() {
    let readme = await GetReadme(this.props.package.name, this.props.version.version)

    this.setState({
      readmeContents: { __html: marked(readme, { sanitize: true }) }
    })
  }

  render() {
    return (
      <div>
        <div dangerouslySetInnerHTML={this.state.readmeContents} className="readme" />
      </div>
    )
  }
}

export default Readme
