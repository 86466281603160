import React, { Component } from "react"

class EditTeamBar extends Component {


  render() {
    if (this.props.organizationPriviledges && this.props.organizationPriviledges.canAddTeam)
      return (
        <div className="EditPackageBarContainer">
          <div className="row">
            <div className="col mini-box mw-180">
              <a href={`/packages/org/${this.props.organization.name}/new-team`}>
                <span className="fa fa-fw fa-users" />
                    New Team
              </a>
            </div>
          </div>
        </div>
      )
    else return <React.Fragment />
  }
}

export default EditTeamBar
