import React, { Component } from "react"
import TitleLeft from "./title_left"

import { PutPackage } from "../scripts/api"
import { getFormData } from "../scripts/misc"

import { Redirect } from "react-router"

import $ from "jquery"

class EditPackageLeft extends Component {
  constructor(props) {
    super(props)

    this.state = {
      redirectID: null,
      package: null,
      formError: null
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  // handleChange(event) {
  //   TODO cover bad input
  // }

  async submitPackageEdit(formData) {
    let response = []
    try {

      response = await PutPackage(this.props.package._id, formData)
      this.setState({ redirectID: response.name })
    } catch (e) {
      $(`button`)[0].disabled = false
      this.setState({ formError: e.response.data.message })
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    $(`button`)[0].disabled = true
    // const formData = new FormData(event.target);
    const formData = getFormData($(event.target))
    formData[`keywords`] = formData[`keywords`].split(`,`);

    this.submitPackageEdit(formData);
  }

  render() {
    let currPackage = this.props.package

    if (!currPackage) return <React.Fragment />
    return (
      <React.Fragment>
        {this.state.redirectID !== null ? (
          <Redirect to={`/package/` + this.state.redirectID} />
        ) : (
            <React.Fragment />
          )}
        <form className="form-horizontal" onSubmit={this.handleSubmit}>
          <TitleLeft title={`Edit Package`} />
          <div className={this.state.formError ? `form-error` : `d-none`}>
            {this.state.formError}
          </div>
          <div className="form-group">
            <div className="row">
              <label htmlFor="forName" className="col-sm-3 control-label">
                Package Name
              </label>
              <div className="col-sm-9">
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  id="forName"
                  placeholder="Package Name"
                  defaultValue={currPackage ? currPackage.name : ``}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label htmlFor="forWebsite" className="col-sm-3 control-label">
                Website
              </label>
              <div className="col-sm-9">
                <input
                  name="url"
                  type="text"
                  className="form-control"
                  id="forWebsite"
                  placeholder="Package Website"
                  defaultValue={currPackage ? currPackage.url : ``}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label htmlFor="forKeywords" className="col-sm-3 control-label">
                Keywords
              </label>
              <div className="col-sm-9">
                <input
                  name="keywords"
                  type="text"
                  className="form-control"
                  id="forKeywords"
                  placeholder="Comma separated list of keywords"
                  defaultValue={
                    currPackage ? currPackage.keywords.join(`, `) : ``
                  }
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label
                htmlFor="forDescription"
                className="col-sm-3 control-label"
              >
                Description
              </label>
              <div className="col-sm-9">
                <textarea
                  name="description"
                  type="text"
                  className="form-control"
                  id="forDescription"
                  placeholder="Package Description"
                  defaultValue={currPackage ? currPackage.description : ``}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label htmlFor="forGithub" className="col-sm-3 control-label">
                Repository Url
              </label>
              <div className="col-sm-9">
                <input
                  name="repoUrl"
                  type="text"
                  className="form-control"
                  id="forGithub"
                  placeholder="https://github.com/user/my-package"
                  defaultValue={currPackage ? currPackage.repoUrl : ``}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="button-wrapper text-center">
              <button type="submit" className="btn">
                Update
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

export default EditPackageLeft
