import React, { Component } from "react"
import TitleLeft from "./title_left"
import { PostOrganization } from "../scripts/api"
import { getFormData } from "../scripts/misc"
import { Redirect } from "react-router"
import $ from "jquery"

class NewOrganization extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    redirectID: null,
    formError: null
  };

  organizationURL = {
    default: `Organization URL`,
    prefix: `https://livekeys.io/packages/org/`
  };

  handleChange(event) {
    let url = event.target.value

    let $el = $(`#organization-url`)
    let $elHidden = $(`#organization-url-hidden`)

    if (!url) url = this.organizationURL.default
    else url = `${this.organizationURL.prefix}${url}`

    $el.html(url)
    $elHidden.val(url)
    //   TODO cover bad input
  }

  async submitOrganization(formData) {
    let response = []
    try {
      response = await PostOrganization(formData)
      this.setState({ redirectID: response.data.name })
    } catch (e) {
      $(`button`)[0].disabled = false
      this.setState({ formError: e.response.data.message })
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    $(`button`)[0].disabled = true
    const formData = getFormData($(event.target));
    this.submitOrganization(formData)
  }

  render() {
    return (
      <React.Fragment>
        {this.state.redirectID !== null ? (
          <Redirect to={`/packages/organization/` + this.state.redirectID} />
        ) : (
            <React.Fragment />
          )}
        <form
          className="form-horizontal newOrganizationForm"
          onSubmit={this.handleSubmit}
        >
          <TitleLeft title={`New Organization`} />
          <div className={this.state.formError ? `form-error` : `d-none`}>
            {this.state.formError}
          </div>

          {/* <div className="form-group">
            <div className="row">
              <div className="col-sm-3" />
              <div className="col-sm-9" style={{ color: "#888" }}>
                www.livecv.io/org
              </div>
            </div>
          </div> */}
          <div className="form-group">
            <div className="row">
              <label htmlFor="forName" className="col-sm-3 control-label">
                Name
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="forName"
                  placeholder="Enter Organization Name"
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-sm-3" />
              <div className="col-sm-9">
                <input
                  id="organization-url-hidden"
                  type="hidden"
                  name="url"
                  className="form-control"
                />
                <div id="organization-url">{this.organizationURL.default}</div>
              </div>
            </div>
          </div>
          {/* <div className="form-group">
            <div className="row">
              <div className="col-sm-3 text-right">Optional</div>
              <div className="col-sm-9">
                You can <a href="#">convert this account to an organization</a>
              </div>
            </div>
          </div> */}
          <div className="form-group">
            <div className="button-wrapper text-center">
              <button type="submit" className="btn">
                Create Organization
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

export default NewOrganization
