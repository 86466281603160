import React, { Component } from "react"

import PackageSearch from "./package_search"
import NewPackageLeft from "./new_package_left"
import MenuRight from "./menu_right"
import OrganizationsRight from "./organizations_right"
import IconRight from "./icon_right"
// import TitleLeft from "../components/title_left";

import { CurrentUser } from "../scripts/api"

class NewOrgPackage extends Component {
  state = {
    currentUser: undefined
  };

  async fetchData() {
    let user = await CurrentUser()
    this.setState({ currentUser: user })
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    return (
      <React.Fragment>
        <PackageSearch user={this.state.currentUser} />
        <div className="row">
          <div className="col-sm-8">
            <NewPackageLeft organization={this.props.match.params.org} />
          </div>
          <div className="col-sm-4">
            <IconRight user={this.state.currentUser} />
            <MenuRight user={this.state.currentUser} />
            <OrganizationsRight />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default NewOrgPackage
