import React from "react"
import { DeleteTeam } from "../scripts/api"
import { confirmAlert } from "react-confirm-alert" // Import
const TeamItem = props => {
  console.log(props)

  function checkDialog() {
    confirmAlert({
      title: `Confirm to submit`,
      message: `Remove team` + ` ` + `?`,
      buttons: [
        {
          label: `Yes`,
          // onClick: () => console.log(type)
          onClick: async () => {
            let id = props.team._id
            console.log(props.team.teamId)
            try {
              // let res = await DeleteTeam(id)
              await DeleteTeam(id)

              // forceUpdate not working
              this.forceUpdate()
            } catch (e) {
              // handle err
            }
          }
        },
        {
          label: `No`
        }
      ]
    })
  }

  return (
    <tr>
      <td>{props.team.name}</td>
      <td className="packagesNumber">{props.team.packagesCount}
      </td>
      <td className="membersNumber"><a href={`/packages/team-members/` + props.team._id}>{props.team.users.length} Members</a></td>
      {props.organizationPriviledges && props.organizationPriviledges.canRemoveTeam ?
        (
          <td className="remove" >
            <div className="col mini-box">
              <a
                href={`#`}
                onClick={() => {
                  checkDialog()
                  return false
                }}
              >
                <span className="fa fa-fw fa-trash-o" />
                Delete
              </a>
            </div>
          </td>
        ) : <React.Fragment />
      }
    </tr>
  )
}

export default TeamItem
