import React, { Component } from "react"
import { createPackagesLink, queryProps, getAvatarInfo } from "../scripts/misc"

// TODO query

class PackageSearch extends Component {
  state = {
    searchTextOutput: null,
    username: null
  };

  defaultValue = null;

  getLink = () => {
    // TODO Process query and add or change search
    let val =
      this.state.searchTextOutput !== null ||
        this.state.searchTextOutput !== undefined
        ? this.state.searchTextOutput
        : this.defaultValue
    return createPackagesLink({
      prop: `q`,
      val: val,
      queryString: this.props.query
    }) /*.bind(this)*/
  };

  inputChangeHandler = event => {
    this.setState({
      searchTextOutput: event.target.value
    })
  };

  enterHandler = event => {
    if (event.keyCode === 13) {
      document.location.href = this.getLink.call(this)
    }
  };

  containingText = () => {
    return (
      <div
        className={
          `col ` +
          (!this.defaultValue || this.defaultValue === ``
            ? `d-none`
            : `searchOutput`)
        }
      >
        {this.props.count} packages containing &quot;{this.defaultValue}
        &quot;
      </div>
    )
  };

  ascArrow = () => {
    return (
      <a
        href={createPackagesLink({
          prop: `order`,
          val: `desc`,
          queryString: this.props.query
        })}
      >
        <i className="fa fa-caret-up" />
      </a>
    )
  }

  descArrow = () => {
    return (
      <a
        href={createPackagesLink({
          prop: `order`,
          val: `asc`,
          queryString: this.props.query
        })}
      >
        <i className="fa fa-caret-down" />
      </a>
    )
  }

  containingOrder = () => {
    let queryObj = queryProps(this.props.query)
    if (!queryObj.sort)
      return <React.Fragment></React.Fragment>

    // Capitalize first letter
    queryObj.sort =
      queryObj.sort.charAt(0).toUpperCase() + queryObj.sort.slice(1)
    return (
      <div
        className={
          `col justify-content-end d-flex searchOutput`
        }
      >
        <span className="search-order">Order: {queryObj.sort}

          {(queryObj.order && queryObj.order === `asc`) ? this.ascArrow() : this.descArrow()}

        </span>
      </div>
    )
  };

  componentDidMount() {
    if (!this.props.search) {
      this.defaultValue = ``
      return
    }

    this.defaultValue = this.props.search
      .trim()
      .split(`%20`)
      .join(` `)
  }

  render() {
    let { backgroundImage, backgroundColor, renderText } = getAvatarInfo(this.props.user)
    var avatar =
      this.props.user !== null && this.props.user !== undefined ? (
        <div className="col-sm-1">
          <div className="dropdown show">
            <a
              className=""
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div
                className="userIcon"
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundColor: backgroundColor
                }}
              >
                {renderText}
              </div>
            </a>

            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <h6 className="dropdown-header">{this.props.user.username}</h6>
              <a
                className="dropdown-item"
                href={`/packages/user/` + this.props.user.username}
              >
                <span className="fa fa-folder-open fa-fw" />
                Packages
              </a>
              <a
                className="dropdown-item"
                href={`/forum/user/` + this.props.user.username}
              >
                <span className="fa fa-user fa-fw" />
                Profile (forum)
              </a>
              <div className="dropdown-divider" />
              <a className="dropdown-item sign-out-link" href="/forum/#logout">
                <span className="fa fa-sign-out fa-fw" />
                Sign Out
              </a>
            </div>
          </div>
        </div>
      ) : (
          <React.Fragment />
        )

    var loginLink =
      this.props.user === null ? (
        <div className="col-sm-3 userLoginSignUp">
          <a className="userLink" href="/forum/login">
            <span className="input-group-text userLink-left">Log In</span>
          </a>
          <a className="userLink" href="/forum/register">
            <span className="input-group-text userLink-right">Join</span>
          </a>
        </div>
      ) : (
          <React.Fragment />
        )

    return (
      <div className="packageSearch">
        <div className="form-group">
          <div className="row">
            {/* <div className={`col-sm-${this.props.user ? "11" : "9"} ${this.props.user === undefined? "d-none":""}`}> */}
            <div className={`col-sm-${this.props.user !== null ? `11` : `9`}`}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="search"
                  placeholder="Search..."
                  onChange={e => this.inputChangeHandler(e)}
                  onKeyDown={e => this.enterHandler(e)}
                  defaultValue={this.defaultValue}
                />
                <div className="input-group-append">
                  <a href={this.getLink.call(this)}>
                    <span
                      className="input-group-text fa fa-search"
                      id="basic-addon2"
                    />
                  </a>
                </div>
              </div>
            </div>
            {avatar}
            {loginLink}
          </div>
        </div>
        <div className="row">{this.containingText()}{this.containingOrder()}</div>
      </div>
    )
  }
}

export default PackageSearch
