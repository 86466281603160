import React, { Component } from "react"
import { Redirect } from "react-router"
import Page404 from "../components/page_404"
import OrganizationRight from "./organization_right"
import TitleLeft from "./title_left"
import PackageSearch from "./package_search"
import { confirmAlert } from "react-confirm-alert" // Import
import { CurrentUser, GetOrganizationByName, DeleteOrganization, PostOrganizationPhoto } from "../scripts/api"

class Organization extends Component {

  state = {
    currentUser: undefined,
    organization: null,
    redirectID: null,
    notFound: null,
    formError: null,
    redirect: <Redirect.Fragment />
  };

  async fetchData() {
    let organization = await GetOrganizationByName(this.props.match.params.name)
    let user = await CurrentUser()
    this.setState({ organization: organization, currentUser: user })
  }

  async removeOrganization() {
    confirmAlert({
      title: `Confirm to submit`,
      message: `Remove organization` + `` + `?`,
      buttons: [
        {
          label: `Yes`,
          // onClick: () => console.log(type)
          onClick: async () => {
            let organization = await GetOrganizationByName(this.props.match.params.name)
            // console.log(organization._id);
            try {
              // let res = await DeleteOrganization(organization._id)
              await DeleteOrganization(organization._id)
              let user = await CurrentUser()
              console.log(user)
              this.setState({ redirectID: `user/` + user.username })
              // this.forceUpdate()

            } catch (e) {
              // handle err
            }
          }
        },

        {
          label: `No`
        }
      ]
    })
  }

  componentDidMount() {
    try {
      if (this.props.match.params.name) {
        this.fetchData()
      }
    } catch (e) {
      this.setState({ notFound: true })
      this.forceUpdate()
      return
    }

    document.title = `Organization | Live Keys`
  }

  async readFile(event) {
    try {
      let fr = new FileReader();
      const resp = await PostOrganizationPhoto(this.state.organization._id, event.target.files[0])
      this.forceUpdate();
    } catch (error) {
      this.setState({ formError: error.response.data.message })
    }
  }

  render() {
    if (this.state.notFound)
      return (
        <Page404></Page404>
      )

    return (
      <React.Fragment>
        {this.state.redirectID !== null ? (
          <Redirect to={`/packages/` + this.state.redirectID} />
        ) : (
            <React.Fragment />
          )}
        <PackageSearch user={this.state.currentUser} />
        <div className="row">
          <div className="col-sm-8">
            <TitleLeft title={`Organization Info`} />

            <div className="EditPackageBarContainer">
              <div className="row">

                <div className={this.state.formError ? `form-error` : `d-none`}>
                  {this.state.formError}
                </div>

                <div className="col mini-box mw-250">
                  <a
                    href={`#`}
                    onClick={() => {
                      this.removeOrganization()
                      return false
                    }}
                  >
                    <span className="fa fa-fw fa-trash-o" />
                    Delete This Organization
                  </a>
                </div>
                <div className="col mini-box mw-180">
                  <input
                    id="organizationphoto"
                    type="file"
                    ref={(ref) => this.uploadPhoto = ref}
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      this.readFile(event)
                    }}
                    onClick={(event) => {
                      event.target.value = null
                    }}
                  />
                  <a
                    href={`#`}
                    onClick={() => {
                      this.uploadPhoto.click()
                      return false;
                    }}
                  >
                    <span className="fa fa-fw fa-picture-o" />
                    Upload Photo
                  </a>
                </div>
              </div>
            </div>

            <div className="row organizationInfo">
              <div className="organizationImage">
                {this.state.organization && this.state.organization.picturePath
                  ? <img src={this.state.organization.picturePath.replace('%current%', '/forum/web-pm-api/')} alt="Organization Image" />
                  : <React.Fragment />}
              </div>
            </div>

          </div>
          <div className="col-sm-4">
            {this.state.organization ? <OrganizationRight organization={this.state.organization} /> : <React.Fragment />}
          </div>
        </div>
      </React.Fragment >
    )
  }
}

export default Organization
