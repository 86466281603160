import React, { Component } from "react"
import TitleLeft from "./title_left"

import {
  PostPackage,
  PostPackageForOrg,
  GetOrganizationByName
} from "../scripts/api"
import { getFormData } from "../scripts/misc"

import { Redirect } from "react-router"

import $ from "jquery"

class NewPackageLeft extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    redirectID: null,
    formError: null
  };

  async submitPackage(formData) {
    let response = []
    try {
      if (this.props.organization) {
        let organization = await GetOrganizationByName(
          this.props.organization
        )
        response = await PostPackageForOrg(organization._id, formData)
      } else {
        response = await PostPackage(formData)
      }
      this.setState({ redirectID: response.name })
    } catch (e) {
      $(`button`)[0].disabled = false
      this.setState({ formError: e.response.data.message })
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    $(`button`)[0].disabled = true
    // const formData = new FormData(event.target);
    const formData = getFormData($(event.target))
    formData[`keywords`] = formData[`keywords`].split(`,`).map(kw => kw.trim()).filter(kw => kw !== '');

    this.submitPackage(formData)
  }

  render() {
    let organization = this.props.organization
    return (
      <React.Fragment>
        {this.state.redirectID !== null ? (
          <Redirect to={`/package/` + this.state.redirectID} />
        ) : (
            <React.Fragment />
          )}
        <form className="form-horizontal" onSubmit={this.handleSubmit}>
          <TitleLeft
            title={`New Package${organization ? ` for ${organization}` : ``}`}
          />
          <div className={this.state.formError ? `form-error` : `d-none`}>
            {this.state.formError}
          </div>

          <div className="form-group">
            <div className="row">
              <label htmlFor="forName" className="col-sm-3 control-label">
                Package Name
              </label>
              <div className="col-sm-9">
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  id="forName"
                  placeholder="Package Name"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label htmlFor="forWebsite" className="col-sm-3 control-label">
                Website
              </label>
              <div className="col-sm-9">
                <input
                  name="url"
                  type="text"
                  className="form-control"
                  id="forWebsite"
                  placeholder="Package Website"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label htmlFor="forKeywords" className="col-sm-3 control-label">
                Keywords
              </label>
              <div className="col-sm-9">
                <input
                  name="keywords"
                  type="text"
                  className="form-control"
                  id="forKeywords"
                  placeholder="Comma separated list of keywords"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label
                htmlFor="forDescription"
                className="col-sm-3 control-label"
              >
                Description
              </label>
              <div className="col-sm-9">
                <textarea
                  name="description"
                  type="text"
                  className="form-control"
                  id="forDescription"
                  placeholder="Package Description"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label htmlFor="forGithub" className="col-sm-3 control-label">
                Repository url
              </label>
              <div className="col-sm-9">
                <input
                  name="repoUrl"
                  type="text"
                  className="form-control"
                  id="forGithub"
                  placeholder="https://github.com/user/my-package"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="button-wrapper text-center">
              <button type="submit" className="btn">
                Add Package
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

export default NewPackageLeft
