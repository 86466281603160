import React, { Component } from "react"

class PackagesBar extends Component {
  render() {
    return (
      <div className="EditPackageBarContainer">
        <div className="row">
          <div className="col mini-box mw-180">
            <a href={this.props.username ? `/packages/new-package` : `/packages/new-package/org/${this.props.organizationName}`}>
              <span className="fa fa-fw fa-plus" />
                New Package
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default PackagesBar
