import React from "react"

const MenuRight = props => {
  let data = props.user ? (
    <div className="organizationRight">
      <div className="rightCaption">Menu</div>
      <ul>
        <a href={`/forum/user/` + props.user.username}>
          <li>Profile</li>
        </a>
        <a href={`/packages/user/` + props.user.username}>
          <li>Packages</li>
        </a>
      </ul>
    </div>
  ) : (
    <React.Fragment />
  )
  return data
}

export default MenuRight
