import React, { Component } from "react"

import PackageTags from "../components/package_tags"
import { DeletePackageVersion, GetOrganization } from "../scripts/api"
// import { Redirect } from "react-router" TODO Redirect

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

import moment from "moment"

class PackageInfo extends Component {
  state = {
    name: undefined,
    link: undefined
  }

  // releaseOptions(releases){
  //   {Object.keys(releases).map((key, i) => (
  //     <p className="release-tag">
  //       {releases[key]}
  //     </p>
  //   ))}
  // }

  iconMap(releaseType) {
    switch (releaseType) {
      case `win`:
        return <span className="fa fa-windows" />
      case `win-dev`:
        return <span className="fa fa-windows"> Dev</span>
      case `linux`:
        return <span className="fa fa-linux" />
      case `linux-dev`:
        return <span className="fa fa-linux"> Dev</span>
      case `macos`:
        return <span className="fa fa-apple" />
      case `macos-dev`:
        return <span className="fa fa-apple"> Dev</span>
    }
    return <span>{releaseType}</span>
  }

  async deleteRelease(versionId) {
    await DeletePackageVersion(versionId)
    //TODO: Refresh
  }

  checkDialog(versionId) {
    var that = this;
    confirmAlert({
      title: `Confirm to submit`,
      message: `Are you sure you want to delete this release?`,
      buttons: [
        {
          label: `Yes`,
          onClick: function () {
            that.deleteRelease(versionId)
          }
        },
        {
          label: `No`
        }
      ]
    })
  }

  deleteReleaseLink(key, link) {
    if (this.props.packagePriviledges.canRelease) {
      return (
        <a
          href={`#`}
          onClick={() => {
            this.checkDialog(link)
            return false
          }}
        >
          <span className="fa fa-trash" />
        </a>
      )
    } else {
      return <React.Fragment />
    }
  }

  iterateReleases() {
    // console.log(this.props.version.releases)
    if (this.props.version && this.props.version.releases) {
      return (
        <div className="release-container">
          {this.props.version.releases.map(release => (
            <div key={release.name} className="release-tag">
              <span className="release-tag-version">{this.props.version.version}</span>
              {this.iconMap(release.release)}
              {this.deleteReleaseLink(release.release, release._id)}
            </div>
          ))}
        </div>
      )
    } else {
      return <React.Fragment />
    }
  }

  async publisher() {
    let user = this.props.user
    let orgId = this.props.orgId

    if (orgId) {
      let org = await GetOrganization(orgId)
      let orgName = org.name
      this.setState(
        {
          name: orgName,
          link: `/packages/org/${orgName}`
        }
      )
    } else if (user) {
      this.setState(
        {
          name: user.username,
          link: `/packages/user/${user.username}`
        }
      )
    }
  }

  componentDidMount() {
    this.publisher()
  }

  published = () => {
    if (this.state.name)
      return (
        <div className="publisher">
          Published
          {this.props.package.user || !this.props.package.updatedAt
            ? ` `
            : ` ` + moment(this.props.package.updatedAt).fromNow() + ` `}
          by{` `}
          <a href={this.state.link}>
            {this.state.name}
          </a>
        </div>
      )
  }

  render() {
    return (
      <div className="packageDetails">
        <p>{this.props.package.description}</p>
        <PackageTags keywords={this.props.package.keywords} />
        <div className="packageInfoBot">
          {this.iterateReleases()}
          {this.published()}
        </div>
      </div>
    )
  }
}

export default PackageInfo
