import React, { Component } from "react"

class Page404 extends Component {
  componentDidMount() {
    document.title = `404 | LiveKeys`
  }

  render() {
    return (
      <div className="row">
        <div className="col page-not-found d-flex flex-column justify-content-center">
          <div className="overflow-hidden">
            <h2 className="page-not-found">404</h2>
          </div>
          <h1 className="page-not-found">
            Page could not be found.
          </h1>
          <section className="page-not-found">
            Perhaps you misspelled the url or it has been removed.
          </section>
        </div>

      </div>


    )
  }
}

export default Page404