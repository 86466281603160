import $ from "jquery"
import * as qs from "query-string"

function getFormData($form) {
  var unindexed_array = $form.serializeArray()
  var indexed_array = {}

  $.map(unindexed_array, function (n, i) {
    indexed_array[n[`name`]] = n[`value`]
  })

  return indexed_array
}

function createPackagesLink({ prop, val, queryString }) {
  let query = {}
  if (queryString) {
    if (queryString.indexOf(`search?`) !== -1)
      queryString = queryString.substring(`search?`.length)

    query = qs.parse(queryString.replace(`keywords:`, `keywords%3A`))
  }

  query[prop] = val

  return (
    `/packages/search?` +
    qs.stringify(query).replace(`keywords%3A`, `keywords:`) //replace escaped %3A back to -> :
  )
}

function queryProps(queryString) {
  if (!queryString)
    return {}

  let query = {}

  if (queryString.indexOf(`search?`) !== -1)
    queryString = queryString.substring(`search?`.length)

  query = qs.parse(queryString.replace(`keywords:`, `keywords%3A`))

  return query
}

function getAvatarInfo(user) {
  let backgroundImage =
    user && user.picture !== ``
      ? user.picture
      : null

  let backgroundColor =
    user ? user.iconBgColor : null

  let renderText = backgroundImage === null && user ? user.iconText : ``

  return { backgroundImage, backgroundColor, renderText }
}

export { getFormData, createPackagesLink, queryProps, getAvatarInfo }
