import React from "react"
import { createPackagesLink } from "../scripts/misc"

//           query={this.props.query}

const PackageTags = props => {
  return (
    <div className="tags">
      {props.keywords ? (
        props.keywords.map(tag => {
          return (
            <a
              href={
                // "/packages/search?" +
                createPackagesLink({
                  prop: `q`,
                  val: `keywords:${tag.trim()}`,
                  queryString: props.queryString
                }) /*.bind(this)*/
              }
              key={tag}
            >
              <div className="tag" key={tag}>
                {tag}
              </div>
            </a>
          )
        })
      ) : (
          <React.Fragment />
        )}
    </div>
  )
}

export default PackageTags
