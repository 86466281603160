import React from "react"
import ReactDOM from "react-dom"
import { Switch, Route, BrowserRouter } from "react-router-dom"

// COMPONENTS
import Header from "./components/header"
import Footer from "./components/footer"
import Download from "./components/download"
import LoginForm from "./components/login_form"
import Package from "./components/package"
import Packages from "./components/packages"
import NewUserPackage from "./components/new_user_package"
import NewOrgPackage from "./components/new_org_package"
import NewVersion from "./components/new_version"
import NewTeam from "./components/new_team"
import NewOrganization from "./components/new_organization"
import Organization from "./components/organization"
import TeamMembers from "./components/team_members"
import Teams from "./components/teams"
import EditPackage from "./components/edit_package"
import Page404 from "./components/page_404"


const App = () => {
  return (
    <div>
      <Header />
      <section className="content-section content-main">
        <div className="container ">
          <BrowserRouter>
            <Switch>
              <Route path="/download" component={Download} />
              <Route path="/login" component={LoginForm} />
              <Route path="/package/:id/:version?" component={Package} />
              {/* <Route path="/package/:id" component={Package} /> */}
              {/* <Route path="/package/" component={Package} /> */}
              <Route
                path="/packages/new-version/:name"
                component={NewVersion}
              />
              <Route exact path="/packages/new-package/org/:org" component={NewOrgPackage} />
              <Route exact path="/packages/new-package" component={NewUserPackage} />

              <Route
                path="/packages/edit-package/:name"
                component={EditPackage}
              />
              <Route exact path="/packages/org/:name/new-team" component={NewTeam} />
              <Route exact path="/packages/new-organization" component={NewOrganization} />


              <Route exact path="/packages/organization/:name" component={Organization} />
              <Route exact path="/packages/team-members/:id" component={TeamMembers} />

              <Route exact path="/packages/teams/:name" component={Teams} />


              <Route exact path="/packages/user/:user/:query?" component={Packages} />
              <Route exact path="/packages/org/:org/:query?" component={Packages} />
              <Route exact path="/packages/:query?" component={Packages} />

              <Route component={Page404} />
            </Switch>
          </BrowserRouter>
        </div>
        <Footer />
      </section>
    </div>
  )
}

ReactDOM.render(<App />, document.querySelector(`#root`))
