import React, { Component } from "react"

import PackageSearch from "../components/package_search"
import PackageInfo from "../components/package_info"
import SortBy from "../components/sort_by"
import TitleLeft from "../components/title_left"
import IconRight from "../components/icon_right"
import MenuRight from "../components/menu_right"
import OrganizationsRight from "../components/organizations_right"
import OrganizationRight from "./organization_right"
import TeamAssign from "./team_assign"
import { queryProps } from "../scripts/misc"

import Page404 from "../components/page_404"

// import * as qs from "query-string"

import {
  GetPackages,
  CurrentUser,
  GetPackagesByUser,
  GetPackagesByOrg,
  GetUser,
  GetOrganizationByName,
  GetPrivilegesForOrganization,
  GetPrivilegesForUser,
  GetOrganizationTeams
} from "../scripts/api"

class Packages extends Component {
  state = {
    packages: null,
    currentUser: undefined,
    organization: null,
    notFound: false
  };

  async getPackagesByUserFunction(username) {
    let user = await GetUser({ name: username })
    if (user) {
      let packages = await GetPackagesByUser(user._id)

      this.setState({ packages: packages })
    } else {
      this.setState({ notFound: true })
      return
    }
  }

  async getPackagesByOrgFunction(orgName) {

    let org = await GetOrganizationByName(orgName)
    if (org) {
      let id = org._id
      let packages = await GetPackagesByOrg(id)
      this.setState({ packages: packages })
    } else {
      this.setState({ notFound: true })
      return
    }
  }

  async getUserAndOrgInfo({ user = false, org = false }) {
    let current = await CurrentUser()

    if (user !== false) {

      let userPriviledges = GetPrivilegesForUser(current, user)

      this.setState({
        user: user,
        currentUser: current,
        userPriviledges: userPriviledges
      })

      this.getPackagesByUserFunction.call(this, user)

      // getPackagesByUserFunction also calls setState.
      // prob will never happen before finishing of upper setState ^^^
    } else if (org !== false) {
      // Organization
      let organization
      try {
        organization = await GetOrganizationByName(org)
        let organizationPriviledges = GetPrivilegesForOrganization(current, organization)

        let organizationTeams = await GetOrganizationTeams(organization._id)

        var organizationTeamsSelection = organizationTeams.map((obj) => {
          return { label: obj.name, value: obj._id }
        })

        this.setState({
          currentUser: current,
          organization: organization,
          organizationPriviledges: organizationPriviledges,
          organizationTeamsSelection: organizationTeamsSelection
        })

        this.getPackagesByOrgFunction.call(this, org)

      } catch (e) {
        this.setState({ notFound: true })
        return
      }

      // getPackagesByUserFunction also calls setState.
      // prob will never happen before finishing of upper setState ^^^
    } else {
      this.setState({ currentUser: current })
    }
  }

  async getSearchPackages() {
    let query = this.props.location.search;
    let qprops = queryProps(query);

    let res = await GetPackages(qprops);

    this.setState({ packages: res }, () =>
      this.getUserAndOrgInfo({ user: false, org: false })
    )
  }

  componentDidMount() {
    if (this.props.match.params.user) {
      this.getUserAndOrgInfo({ user: this.props.match.params.user })
    } else if (this.props.match.params.org) {
      this.getUserAndOrgInfo({ org: this.props.match.params.org })
    } else {
      let query = this.props.location.search;
      let qprops = queryProps(query);

      this.getSearchPackages();
    }

    document.title = `Packages | LiveKeys`
  }

  printPackages() {
    // if (!this.state.user) return;

    let packages = this.state.packages
    let query = this.props.location.search

    // let showTeamAssign = false
    // if (this.state.user) {

    // }

    if (packages != null && packages.length > 0) {
      return packages.map(function (pkg) {
        return (
          <div key={pkg._id} className="packageInfo">
            <a href={`/package/` + pkg.name}>
              <h2>{pkg.name}</h2>
            </a>

            <div className="row">
              {this.state.organization &&
                this.state.organizationPriviledges.canAssignPackage ? (
                  <div className={`col-lg-3`}>
                    <TeamAssign
                      package={pkg}
                      organization={this.state.organization}
                      teams={this.state.organizationTeamsSelection}
                    />
                  </div>
                ) : (
                  <React.Fragment />
                )}

              <div className="col">
                <PackageInfo
                  package={pkg}
                  version={pkg.version}
                  user={pkg.user}
                  orgId={pkg.organization}
                  query={query}
                />
              </div>
            </div>
          </div>
        )

      }.bind(this))

    }
  }

  packagesRight() {
    if (this.props.match.params.org) {
      if (this.state.organization)
        return (<OrganizationRight organization={this.state.organization} />)
    } else if (this.props.match.params.user) {
      return (
        <React.Fragment>
          <IconRight user={this.state.currentUser} />
          <MenuRight user={this.state.currentUser} />
          <OrganizationsRight />

        </React.Fragment>
      )
    } else {
      // FIXME
      return <SortBy query={this.props.location.search} />
    }
  }

  packageCount() {
    let packages = this.state.packages
    if (packages != null) return packages.length
  }

  render() {
    var Title

    let organization = this.state.organization

    let query = this.props.location.search

    if (this.props.match.params.org && organization) {
      Title = <TitleLeft title="Packages for"
        organizationName={organization.name} />
    } else if (this.props.match.params.user) {
      Title = <TitleLeft
        title="Packages for"
        username={this.props.match.params.user}
        userPriviledges={this.state.userPriviledges} />
    } else {
      Title = <React.Fragment />
    }

    if (this.state.notFound)
      return (
        <Page404></Page404>
      )

    return (
      <div>
        <PackageSearch
          search={queryProps(query).q}
          count={this.packageCount()}
          user={this.state.currentUser}
          query={this.props.location.search}
        />
        <div className="row">
          <div className="col-sm-8">
            {Title}
            {this.printPackages()}
          </div>
          <div className="col-sm-4">{this.packagesRight()}</div>
        </div>
      </div>
    )
  }
}

export default Packages
