import React, { Component } from "react"
import TitleLeft from "./title_left"
import Select from "react-select"
import { PostPackageRelease } from "../scripts/api"
import { getFormData } from "../scripts/misc"

import { Redirect } from "react-router"

import $ from "jquery"

const releaseType = [
  { label: `win`, value: `win` },
  { label: `win-dev`, value: `win-dev` },
  { label: `linux`, value: `linux` },
  { label: `linux-dev`, value: `linux-dev` },
  { label: `macos`, value: `macos` },
  { label: `macos-dev`, value: `macos-dev` },
]
class NewVersionLeft extends Component {

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  selectStyle = {
    control: base => ({
      ...base,
      border: `0 !important`,
      boxShadow: `0 !important`,
      backgroundColor: `#111`,
      color: `#fff !important`,
      "&:hover": {
        border: `0 !important`
      }
    }),
    option: (styles, { _, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: `#111`,
      "&:hover": {
        color: `#fff`,
        backgroundColor: `#191919`
      },
      color: isSelected || isFocused ? `#fff` : `#fff`,
      cursor: isDisabled ? `not-allowed` : `default`,
      borderRadius: 8
    }),
    menu: base => ({
      ...base,
      backgroundColor: `#111`,
      padding: 4,
      borderRadius: 6
    }),
    singleValue: base => ({
      ...base,
      color: `#fff`
    })
  };


  state = {
    redirectID: null,
    formError: null
  };

  componentDidMount() {
  }

  async submitNewVersion(formData) {
    try {
      // let response = await PostPackageRelease(formData)
      await PostPackageRelease(formData)
      this.setState({ redirectID: this.props.package.name })
    } catch (e) {
      $(`button`)[0].disabled = false
      this.setState({ formError: e.response.data.message })
    }
  }

  handleSubmit(event) {
    if (!this.props.package)
      return

    event.preventDefault()
    $(`button`)[0].disabled = true
    // const formData = new FormData(event.target);
    const formData = getFormData($(event.target))
    let dependencies = {}
    formData[`dependencies`]
      .split(`,`)
      .forEach(v => {
        v = v.trim().split(` `)
        if (v.length != 2) {
          this.setState({ formError: "Invalid dependencies. Must be in the form of: <package_name> <version>, ..." })
        } else {
          return dependencies[v[0]] = v[1]
        }
      })

    formData[`dependencies`] = dependencies
    formData[`package`] = this.props.package._id;

    this.submitNewVersion(formData)
  }

  render() {
    if (!this.props.package)
      return (<React.Fragment />)

    return (
      <React.Fragment>
        {this.state.redirectID !== null ? (
          <Redirect to={`/package/` + this.state.redirectID} />
        ) : (
            <React.Fragment />
          )}
        <TitleLeft title={`New version for  ` + this.props.package.name} />
        <div className={this.state.formError ? `form-error` : `d-none`}>
          {this.state.formError}
        </div>
        <form className="form-horizontal" onSubmit={this.handleSubmit}>
          <div className="form-group">
            <div className="row">
              <label htmlFor="forVersion" className="col-sm-3 control-label">
                Version
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  id="forVersion"
                  name="version"
                  placeholder="Semantic Version (i.e. 1.2.3.)"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label
                htmlFor="forDependencies"
                className="col-sm-3 control-label"
              >
                Dependencies
              </label>
              <div className="col-sm-9">
                <input
                  name="dependencies"
                  type="text"
                  className="form-control"
                  id="forDependencies"
                  placeholder="Comma separeted list of dependencies with version major.minor (i.e. opencv1, cuda9)"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label htmlFor="release" className="col-sm-3 control-label">
                Release
              </label>
              <div className="col-sm-9">
                <Select
                  name="release"
                  type="text"
                  id="release"
                  placeholder="Release Type"
                  options={releaseType}
                  styles={this.selectStyle}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label htmlFor="url" className="col-sm-3 control-label">
                Url
              </label>
              <div className="col-sm-9">
                <input
                  name="url"
                  type="text"
                  className="form-control"
                  id="url"
                  placeholder="Url to the release."
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="button-wrapper text-center">
              <button type="submit" className="btn">
                Add Version
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

export default NewVersionLeft
