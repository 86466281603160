import React from "react"

const OrganizationRight = props => {
  return (
    <div className="organizationRight">
      <div className="rightCaption">Organization</div>
      <ul>
        <a href={`/packages/organization/` + props.organization.name}>
          <li>Profile</li>
        </a>
        <a href={`/packages/org/` + props.organization.name}>
          <li>Packages</li>
        </a>
        <a href={`/packages/teams/` + props.organization.name}>
          <li>Teams</li>
        </a>
      </ul>
    </div>
  )
}

export default OrganizationRight
