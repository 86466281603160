import React, { Component } from "react"

import PackageSearch from "./package_search"
import EditPackageLeft from "./edit_package_left"
import MenuRight from "./menu_right"
import OrganizationsRight from "./organizations_right"
import IconRight from "./icon_right"
// import TitleLeft from "../components/title_left";

import { CurrentUser, GetPackageByName } from "../scripts/api"

class EditPackage extends Component {
  state = {
    currentUser: undefined
  };

  async fetchData() {
    let pkg = await GetPackageByName(this.props.match.params.name)
    let u = await CurrentUser()
    this.setState({
      package: pkg,
      currentUser: u
    })
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    return (
      <React.Fragment>
        <PackageSearch user={this.state.user} />{` `}
        <div className="row">
          <div className="col-sm-8">
            <EditPackageLeft
              package={this.state.package}
            />{` `}
          </div>{` `}
          <div className="col-sm-4">
            <IconRight user={this.state.user} /> <MenuRight />
            <OrganizationsRight />
          </div>{` `}
        </div>{` `}
      </React.Fragment>
    )
  }
}

export default EditPackage
