import React, { Component } from "react"

import {
  GetVersionByName,
  DeletePackageVersion,
  DeleteUserPackage,
  PostReadme
} from "../scripts/api"

import Page404 from "../components/page_404"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

class EditPackageBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notFound: null,
      formError: null
    }
  }

  async deletePackage() {
    await DeleteUserPackage(this.props.package._id);
    this.setState({ notFound: true })
  }

  checkDialog(type) {
    confirmAlert({
      title: `Confirm to submit`,
      message: `Are you sure to do this.`,
      buttons: [
        {
          label: `Yes`,
          onClick: () => {
            this.deletePackage();
          }
        },
        {
          label: `No`
        }
      ]
    })
  }

  async readFile(event) {
    try {
      let fr = new FileReader();
      const resp = await PostReadme(this.props.package.name, this.props.version, event.target.files[0])
    } catch (error) {
      this.setState({ formError: error.response.data.message })
    }
  }

  render() {
    if (this.state.notFound)
      return (
        <Page404></Page404>
      )

    if (this.props.packagePriviledges && this.props.packagePriviledges.canWrite)
      return (
        <div className="EditPackageBarContainer">
          <div className="row">

            <div className={this.state.formError ? `form-error` : `d-none`}>
              {this.state.formError}
            </div>

            <div className="col mini-box mw-180">
              <a href={`/packages/new-version/` + this.props.package.name}>
                <span className="fa fa-fw fa-plus" />
                New Version
              </a>
            </div>
            <div className="col mini-box mw-180">
              <input
                id="readmefile"
                type="file"
                ref={(ref) => this.uploadReadme = ref}
                style={{ display: 'none' }}
                onChange={(event) => {
                  this.readFile(event)
                }}
                onClick={(event) => {
                  event.target.value = null
                }}
              />
              <a
                href={`#`}
                onClick={() => {
                  this.uploadReadme.click()
                  return false;
                }}
              >
                <span className="fa fa-fw fa-file" />
                Upload Readme
              </a>
            </div>
            <div className="col mini-box mw-180">
              <a href={`/packages/edit-package/` + this.props.package.name}>
                <span className="fa fa-fw fa-pencil-square-o" />
                Edit Package
              </a>
            </div>
            <div className="col mini-box mw-180">
              <a
                href={`#`}
                onClick={() => {
                  this.checkDialog(`package`)
                  return false
                }}
              >
                <span className="fa fa-fw fa-trash-o" />
                Delete Package
              </a>
            </div>

          </div>
        </div>
      )
    else return <React.Fragment />
  }
}

export default EditPackageBar
