import React, { Component } from "react"
import { getAvatarInfo } from "../scripts/misc"


class AuthorsRight extends Component {
  render() {
    let authors = []
    if (this.props.teamUsers) {
      for (let user of this.props.teamUsers) authors.push(user)
    } else if (this.props.nodeBBUserOwner) {
      authors.push(this.props.nodeBBUserOwner)
    }
    console.log(authors)


    return (
      <div>
        <div className="rightCaption">Authors</div>
        <div className="row authorIcons">
          {authors && authors.length > 0 ? (
            authors.map(function(author){
              let { backgroundImage, backgroundColor, renderText } = getAvatarInfo(author)

              return (
                <div
                  key={author.username}
                  className="col-xl-4 col-lg-4 col-md-6"
                >
                  <a href={`/packages/user/${author.username}`}>
                    <div
                      className="authorIcon"
                      style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundColor: backgroundColor
                      }}
                    >
                      {renderText}
                    </div>
                  </a>
                </div>
              )
            })
          ) : (
            <React.Fragment />
          )}
        </div>
        <a href="#" className="authorsOrganizationLink">
          {this.props.repoUrl}
        </a>
        <br />
      </div>
    )
  }
}

export default AuthorsRight
