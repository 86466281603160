import React, { Component } from "react"

import { CurrentUser } from "../scripts/api"

class OrganizationsRight extends Component {
  state = {
    orgs: null
  };

  async fetchData() {
    let response = await CurrentUser()
    let orgs = response ? response.organizations : null
    this.setState({
      orgs: orgs ? orgs.owner.concat(orgs.admin).concat(orgs.user) : []
    })
  }

  render() {
    if (!this.state.orgs) {
      this.fetchData()
    }

    let orgs = this.state.orgs
    orgs = orgs ? (
      orgs.map(org => (
        <a key={org._id} href={`/packages/organization/` + org.name}>
          <li>{org.name}</li>
        </a>
      ))
    ) : (
      <React.Fragment />
    )

    return (
      <div className="organizationRight">
        <div className="rightCaption">Organizations</div>
        <ul>{orgs}</ul>
        <div className="newOrganization">
          <a href={`/packages/new-organization`}>
            <span className="fa fa-fw fa-plus" />
            New Organization
          </a>
        </div>
      </div>
    )
  }
}

export default OrganizationsRight
