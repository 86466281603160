import React, { Component } from "react"

import {
  GetTeam,
  DeleteUserFromTeam,
  InviteTeamMember,
  GetUser,
  InviteOrganizationMember,
  GetOrganization,
  InviteOrganizationAdmin,
  RemoveOrganizationAdmin,
  CurrentUser,
  GetPrivilegesForOrganization
} from "../scripts/api"

import OrganizationRight from "./organization_right"
import TitleLeft from "./title_left"
import PackageSearch from "./package_search"
import Select from "react-select"

import Page404 from "../components/page_404"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import { getFormData } from "../scripts/misc"
import $ from "jquery"

class TeamMembers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      team: null,
      currentUser: undefined,
      usersData: [],
      organization: null,
      role: null,
      notFound: false
    }

    // this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleUserRoles = this.handleUserRoles.bind(this)
  }

  userType = [
    { label: `admin`, value: `admin` },
    { label: `user`, value: `user` }
  ]

  checkDialog(user) {
    confirmAlert({
      title: `Confirm to submit`,
      message: `Are you sure you want to remove ` + user.username + `?`,
      buttons: [
        {
          label: `Yes`,
          onClick: async () => {
            let teamId = this.props.match.params.id
            let userId = user._id
            try {
              // let res = await DeleteUserFromTeam(teamId, userId);
              await DeleteUserFromTeam(teamId, userId)
              this.fetchData()
            } catch (e) {
              this.setState({ formError: e.response.data.message })
            }
          }
        },
        {
          label: `No`
        }
      ]
    })
  }

  // move requests to api
  async fetchData() {
    let user = await CurrentUser()

    let teamData
    try {
      teamData = await GetTeam(this.props.match.params.id)
    } catch (e) {
      this.setState({ notFound: true })
      return
    }
    let organization = await GetOrganization(teamData.organization)

    let organizationPriviledges = GetPrivilegesForOrganization(user, organization)

    let usersData = []
    for (const uid of teamData.users) {
      let teamuser = await fetch(`/forum/web-pm-api/user?id=${uid}`)
      teamuser = await teamuser.json()
      teamuser = teamuser.data

      usersData.push(teamuser)
    }

    this.setState({
      usersData: usersData,
      team: teamData,
      organization: organization,
      organizationPriviledges: organizationPriviledges,
      currentUser: user
    })
  }

  async submitUserInvite(formData) {
    try {
      let user = await GetUser({ name: formData.username })
      if (!user) {
        $(`button`)[0].disabled = false
        this.setState({ formError: "User wasn't specified." })
        return
      }
      // let response = await InviteOrganizationMember(this.state.organization._id, user._id)
      await InviteOrganizationMember(this.state.organization._id, user._id)
      // let teamResponse = await InviteTeamMember(this.state.team._id, user._id)
      await InviteTeamMember(this.state.team._id, user._id)
      this.fetchData()
    } catch (e) {
      $(`button`)[0].disabled = false
      this.setState({ formError: e.response.data.message })
    }
  }

  handleSubmit(event) {
    event.preventDefault()

    const formData = getFormData($(event.target));
    this.submitUserInvite(formData);
  }

  async submitUserRoles(type, userId, orgId) {
    try {
      if (type === `admin`) {
        // let response = await InviteOrganizationAdmin(orgId, userId)
        let response = await InviteOrganizationAdmin(orgId, userId)
      } else if (type === `user`) {
        let response = await RemoveOrganizationAdmin(orgId, userId)
      }
    } catch (e) {
      console.log(e)
    }
  }

  handleUserRoles(event) {

    let type = event.value
    let userId = event.user._id
    let orgId = this.state.organization._id;

    this.submitUserRoles(type, userId, orgId)
  }

  componentDidMount() {
    this.fetchData()
  }


  usersList() {
    let userData = this.state.usersData

    if (userData) {
      return this.state.usersData.map(user => {
        let userOptions = this.userType.map(obj => {
          return {
            value: obj.value,
            user: user,
            label: obj.label
          }
        })

        return <tr key={user._id}>
          <td className="iconData">
            <div className="icon" />
          </td>
          <td>{user.username}</td>
          <td>
            {this.state.organization && this.state.organization.owner === user._id
              ? <span className="table-owner">Owner</span>
              // : <React.Fragment />
              : this.state.organizationPriviledges && this.state.organizationPriviledges.canPromoteUser
                ? <Select
                  name="user-type"
                  type="text"
                  id="user-type"
                  defaultValue={
                    this.state.organization && this.state.organization.admins.indexOf(user._id) !== -1
                      ? { value: `admin`, user: user, label: `admin` }
                      : { value: `user`, user: user, label: `user` }
                  }
                  options={userOptions}
                  onChange={this.handleUserRoles}
                />
                : <span className="table-user">
                  {this.state.organization && this.state.organization.admins.indexOf(user._id) !== -1
                    ? `Admin`
                    : `User`}
                </span>
            }
          </td>
          <td>
            {this.state.organization && this.state.organization.owner === user._id
              ? <React.Fragment />
              : this.state.organizationPriviledges && this.state.organizationPriviledges.canRemoveUser
                ? <a
                  href={`#`}
                  onClick={() => {
                    this.checkDialog(user)
                    return false
                  }}
                >
                  <span className="fa fa-fw fa-trash-o" />
                </a>
                : <React.Fragment />
            }
          </td>
        </tr>
      })
    } else {
      return <React.Fragment />
    }
  }

  render() {
    let team = this.state.team
    // let users = team ? team.users : <React.Fragment />

    if (this.state.notFound)
      return (
        <Page404></Page404>
      )

    return (
      <React.Fragment>
        <PackageSearch user={this.state.currentUser} />

        <div className="row">
          <div className="col-sm-8">
            <TitleLeft
              title={`Team` + (team ? ` ` + team.name : ``) + ` members`}
              objectName={`Invite`}
            />

            {this.state.organizationPriviledges && this.state.organizationPriviledges.canInviteUser
              ?
              <div className="titleLeft">
                <div className="EditPackageBarContainer">
                  <div className={this.state.formError ? `form-error` : `d-none`}>
                    {this.state.formError}
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <input className="w-auto d-inline form-control" type="text" name="username" id="username" placeholder="Invite User" username={this.state.username} onChange={this.handleChange} />
                    <input className="w-auto d-inline form-control form-button" type="submit" value="Invite" />
                  </form>
                </div>
              </div>
              : <React.Fragment />
            }

            <table className="table teamMembersTable">
              <tbody>
                {this.usersList()}
              </tbody>
            </table>

          </div>
          <div className="col-sm-4">
            {this.state.organization ? <OrganizationRight organization={this.state.organization} /> : <React.Fragment />}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default TeamMembers