import React, { Component } from "react"

import { CurrentUser } from "../scripts/api"

import PackageSearch from "./package_search" //TODO Package?
import NewOrganizationLeft from "./new_organization_left"
import OrganizationsRight from "./organizations_right"
import MenuRight from "./menu_right"
import IconRight from "./icon_right"

class NewOrganization extends Component {
  state = {
    currentUser: undefined
  };

  async fetchData() {
    let user = await CurrentUser()
    this.setState({ currentUser: user })
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    return (
      <React.Fragment>
        <PackageSearch user={this.state.currentUser} />
        <div className="row">
          <div className="col-sm-8">
            <NewOrganizationLeft />
          </div>
          <div className="col-sm-4">
            <IconRight user={this.state.currentUser} />
            <MenuRight user={this.state.currentUser} />
            <OrganizationsRight />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default NewOrganization
