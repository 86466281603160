import React from "react"
import { createPackagesLink } from "../scripts/misc"

const SortBy = props => {
  return (
    <div>
      <div className="rightCaption">
        Sort By
      </div>
      <ul className="sortByList">
        <a
          href={createPackagesLink({
            prop: `sort`,
            val: `popularity`,
            queryString: props.query
          })}
        >
          <li>Popularity</li>
        </a>
        <a
          href={createPackagesLink({
            prop: `sort`,
            val: `maintenance`,
            queryString: props.query
          })}
        >
          <li>Maintenance</li>
        </a>
        <a
          href={createPackagesLink({
            prop: `sort`,
            val: `recent`,
            queryString: props.query
          })}
        >
          <li>Recent</li>
        </a>
      </ul>
    </div>
  )
}

export default SortBy
