import React from "react"

const Header = () => {
  return (
    <nav className="navbar navbar-expand-md navbar-custom fixed-top navbar-dark" role="navigation">
      <div className="container">
        <a className="navbar-brand" href="https://livekeys.io">
          <img src="/img/livekeys-logo-top.png" alt="Live Keys link" />
        </a>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div id="navbarSupportedContent" className="collapse navbar-collapse navbar-right navbar-main-collapse">
          <ul className="nav navbar-nav ml-auto">
            <li id="nav-home">
              <a href="/">Home</a>
            </li>
            <li id="nav-download">
              <a href="/download">Download</a>
            </li>
            <li id="nav-packages">
              <a href="/packages">Packages</a>
            </li>
            <li id="nav-documentation">
              <a href="/docs">Docs</a>
            </li>
            <li id="nav-github">
              <a className="github-link" href="https://github.com/live-keys/livekeys">Source</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="github-redirect">
        <div className="container">
          <div className="github-redirect-info">
            Redirecting you to github...
          </div>
          <div className="github-redirect-cancel">
            <a href="#github-cancel">
              CANCEL
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
