import React from "react"

import InstallRight from "./install_right"
import VersionsRight from "./versions_right"
import ReferencesRight from "./references_right"
import AuthorsRight from "./authors_right"
// import OrganizationRight from "./organization_right";

const PackageRight = props => {
  return (
    <div className="packageRight">
      <InstallRight
        package={props.package}
        version={props.version}
        versions={props.versions}
      />
      {/* TODO AUTHORS */}
      <AuthorsRight
        repoUrl={props.package.repoUrl}
        nodeBBUserOwner={props.nodeBBUserOwner}
        teamUsers={props.teamUsers}
      />
      <VersionsRight
        package={props.package}
        versions={props.versions}
        version={props.version}
      />
      <ReferencesRight version={props.version} versions={props.versions} />
    </div>
  )
}

export default PackageRight
