import React, { Component } from "react"

import PackageSearch from "../components/package_search"
import PackageLeft from "../components/package_left"
import PackageRight from "../components/package_right"
import EditPackageBar from "../components/edit_package_bar"
import PackageNoVersion from "../components/package_no_version"
import Page404 from "../components/page_404"

import {
  GetPackageByName,
  GetPackageVersions,
  GetVersionReleases,
  GetPrivilegesForPackage,
  GetUser,
  CurrentUser,
  GetNodeBBUserByUsername
} from "../scripts/api"

// import Readme from "./readme"

class Package extends Component {
  state = {
    package: null,
    versions: null,
    version: null,
    currentUser: undefined,
    packagePriviledges: null,
    notFound: null
  };

  async getPackageOwner(pkg) {
    if (pkg.team) {
      return { teamUsers: await this.getTeamUsers(pkg.team.users) }
    } else if (pkg.user) {
      return { nodeBBUserOwner: await this.getNodeUserInfo(pkg.user.username) }
    }
  }

  async getNodeUserInfo(username) {
    let nodeBBUserOwner = undefined
    try {
      nodeBBUserOwner = await GetNodeBBUserByUsername(username)
      return nodeBBUserOwner
    } catch (e) {
      console.log(e)
    }
  }

  async getTeamUsers(users) {
    let teamUsers = []
    for (let uid of users) {
      let usr = await GetUser({ id: uid })
      let nodeBBUser = await GetNodeBBUserByUsername(usr.username)

      teamUsers.push(nodeBBUser)
    }
    return teamUsers
  }

  async fetchData() {
    let packageId = this.props.match.params.id
    let version = this.props.match.params.version;

    let pkg
    try {
      pkg = await GetPackageByName(packageId)
    } catch (e) {
      this.setState({ notFound: true })
      return
    }

    let packageVersions = await GetPackageVersions(pkg._id)

    let packageVersion = null

    // select last version
    if (!version) {
      if (packageVersions.length)
        packageVersion = await GetVersionReleases(pkg._id, packageVersions[0].version)
    } else {
      packageVersion = await GetVersionReleases(pkg._id, version)
      // check if version exists
      if (!packageVersion) {
        this.setState({ notFound: true })
        return
      }
    }

    let user = await CurrentUser()
    let packagePriviledges = await GetPrivilegesForPackage(user, pkg)
    let owner = await this.getPackageOwner(pkg)

    this.setState({
      package: pkg,
      versions: packageVersions,
      version: packageVersion,
      currentUser: user,
      packagePriviledges: packagePriviledges,
      ...owner
    })
  }

  componentDidMount() {
    this.fetchData()
  }

  packageLeft() {
    if (this.state.version) {
      return (
        <PackageLeft
          package={this.state.package}
          version={this.state.version}
          versions={this.state.versions}
          loggeduser={this.state.currentUser}
          packagePriviledges={this.state.packagePriviledges}
        />
      )
    } else return this.state.package ? <PackageNoVersion packageId={this.state.package._id} /> : <React.Fragment />
  }

  packageRight() {
    if (this.state.version)
      return (
        <PackageRight
          package={this.state.package}
          version={this.state.version}
          versions={this.state.versions}
          user={this.state.currentUser}
          teamUsers={this.state.teamUsers}
          nodeBBUserOwner={this.state.nodeBBUserOwner}
        />
      )
    else return <React.Fragment />
  }

  render() {
    let versionNumber = this.state.version ? this.state.version.version : null
    if (this.state.notFound)
      return (
        <Page404></Page404>
      )
    return (
      <React.Fragment>
        <PackageSearch user={this.state.currentUser} />
        <div className="row">
          <div className="col-sm-8">

            <div className="titleLeft">
              <h2>{this.state.package ? this.state.package.name : ``}</h2>
              <div className="titleOptions">
                <EditPackageBar
                  user={this.props.user}
                  package={this.state.package}
                  version={versionNumber}
                  packagePriviledges={this.state.packagePriviledges}
                />
              </div>
            </div>
            {this.packageLeft()}
          </div>
          <div className="col-sm-4">{this.packageRight()}</div>
        </div>

      </React.Fragment>

    )
  }
}

export default Package
