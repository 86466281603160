import React, { Component } from "react"
import EditPackageBar from "./edit_package_bar"
import EditTeamBar from "./edit_team_bar"
import PackagesBar from "./packages_bar"

let title = ``,
  newObject = <React.Fragment />

class TitleLeft extends Component {
  render() {

    // Package edit bar
    if (this.props.packageId) {

      newObject = this.props.isOwner ? (
        <EditPackageBar
          isOwner={this.props.isOwner}
          loggeduser={this.props.loggeduser}
          user={this.props.user}
          pid={this.props.packageId}
          version={this.props.version}
        />
      ) : (
        <React.Fragment />
      )

      if (this.props.packageTitle) title = this.props.packageTitle
      if (this.props.version) title += ` ` + this.props.version

      if (this.props.title) title = this.props.title

      // Team Edit Bar

    } else if (this.props.title === `Teams`) {

      title = this.props.title

      if (this.props.organization)
        newObject = <EditTeamBar
          isOwner={this.props.isOwner}
          loggeduser={this.props.loggeduser}
          user={this.props.user}
          pid={this.props.packageId}
          version={this.props.version}
          organization={this.props.organization}
          organizationPriviledges={this.props.organizationPriviledges}
        />

      // NEW TEAM
      // linkHref = "/packages/new_team/";
      // objectName = "New Team";

      // newObject = (
      //   <a href={linkHref}>
      //     <div className="newObject">{objectName}</div>{" "}
      //     <div className="plus">+</div>
      //   </a>
      // );
    } else if (this.props.title == `Packages for` && this.props.organizationName) {
      newObject = (
        <PackagesBar organizationName={this.props.organizationName} />
      )

      title = this.props.title + ` ` + this.props.organizationName

    } else if (this.props.title == `Packages for` && this.props.username) {
      if (this.props.userPriviledges)
        newObject = this.props.userPriviledges.canCreatePackage ? (
          <PackagesBar
            username={this.props.username}
          />
        ) : <React.Fragment />

      title = this.props.title + ` ` + this.props.username

    }

    else {
      title = this.props.title
    }

    return (
      <div className="titleLeft">
        <h2>{title}</h2>
        <div className="titleOptions">{newObject}</div>
      </div>
    )
  }
}

export default TitleLeft
