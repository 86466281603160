import React from "react"

const VersionsRight = props => {
  return (
    <div>
      <div className="rightCaption">Versions</div>
      <ul>
        {props.versions.map(package_details => {
          return (
            <a
              className={
                package_details.version === props.version
                  ? `rightCaptionCurrent`
                  : ``
              }
              key={package_details.version}
              href={
                `/package/` + props.package.name + `/` + package_details.version
              }
            >
              <li key={package_details.version}>{package_details.version}</li>
            </a>
          )
        })}
      </ul>
    </div>
  )
}

export default VersionsRight
