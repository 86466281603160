import React from "react"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

import { DeleteUserPackage } from "../scripts/api"

const PackageNoVersion = props => {
  return (
    <div className="packageNoVersion">
      This package currently has no versions.
    </div>
  )
}

export default PackageNoVersion
