import React, { Component } from "react"

import OrganizationRight from "./organization_right"
import TitleLeft from "./title_left"
import PackageSearch from "./package_search"
import TeamItem from "./team_item"
import Page404 from "../components/page_404"
import { GetOrganizationTeams } from "../scripts/api"
import {
  CurrentUser,
  GetOrganizationByName,
  GetPrivilegesForOrganization
  // GetUserByUsername
} from "../scripts/api"

class Teams extends Component {
  constructor(props) {
    super(props)

    this.state = {
      teams: null,
      organization: null,
      currentUser: undefined,
      organizationPriviledges: null,
      notFound: null
    }
  }

  async fetchData() {
    let organization
    try {
      organization = await GetOrganizationByName(this.props.match.params.name)
    } catch (e) {
      this.setState({notFound: true})
      return
    }

    let user = await CurrentUser()
    let teams = await GetOrganizationTeams(organization._id)
    let organizationPriviledges = await GetPrivilegesForOrganization(user, organization)
    this.setState({ organization: organization, teams: teams, currentUser: user, organizationPriviledges: organizationPriviledges })
  }

  componentDidMount() {
    this.fetchData()
  }

  printTeams() {
    let teams = this.state.teams

    if (teams && teams.length > 0) {
      return teams.map(function (team) {
        return <TeamItem organizationPriviledges={this.state.organizationPriviledges} team={team} key={team._id} />
      }.bind(this))
    }

    // return <TeamItem />;
  }

  render() {
    if(this.state.notFound)
      return (
        <Page404></Page404>
      )

    return (
      <React.Fragment>
        <PackageSearch user={this.state.currentUser} />
        <div className="row">
          <div className="col-sm-8">
            <TitleLeft
              organizationPriviledges={this.state.organizationPriviledges}
              organization={this.state.organization}
              title={`Teams`}
            />
            <table className="table teamsTable">
              <tbody>{this.printTeams()}</tbody>
            </table>
          </div>
          <div className="col-sm-4">
            {this.state.organization ? <OrganizationRight organization={this.state.organization} /> : <React.Fragment />}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Teams
