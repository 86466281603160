import React, { Component } from "react"
import Select from "react-select"

import { AsignPackageToTeam } from "../scripts/api"

class TeamAsign extends Component {

  selectStyle = {
    control: base => ({
      ...base,
      border: `0 !important`,
      boxShadow: `0 !important`,
      backgroundColor: `#111`,
      color: `#fff !important`,
      "&:hover": {
        border: `0 !important`
      }
    }),
    option: (styles, { _, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: `#111`,
      "&:hover": {
        color: `#fff`,
        backgroundColor: `#191919`
      },
      color: isSelected || isFocused ? `#fff` : `#fff`,
      cursor: isDisabled ? `not-allowed` : `default`,
      borderRadius: 8
    }),
    menu: base => ({
      ...base,
      backgroundColor: `#111`,
      padding: 4,
      borderRadius: 6
    }),
    singleValue: base => ({
      ...base,
      color: `#fff`
    })
  };

  state = {
    selectedOption: null
  };

  componentDidMount() {
    if (this.props.package.team) {
      let team = this.props.package.team

      for (let i = 0; i < this.props.teams.length; ++i) {
        if (this.props.teams[i].value === team) {
          this.setState({ selectedOption: this.props.teams[i] })
        }
      }
    }
  }

  async submitPackageToTeamAssignment(selectedOption) {
    await AsignPackageToTeam(this.props.package._id, selectedOption.value)
    this.setState({ selectedOption })
  }

  handleChange(selectedOption) {
    this.submitPackageToTeamAssignment(selectedOption);
  }

  render() {
    const { selectedOption } = this.state

    return (
      <div className="teamAsign EditPackageBarContainer">
        Team:
        <Select
          value={selectedOption}
          options={this.props.teams}
          onChange={this.handleChange}
          styles={this.selectStyle}
        />
      </div>
    )
  }
}
export default TeamAsign
