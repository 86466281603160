import React, { Component } from "react"

class Download extends Component {

  componentDidMount() {
    document.title = `Downloads | LiveKeys`
  }

  render() {
    return (
      <div>
        <h1>Downloads for version 1.9</h1>
        <div className="row">
          <div className="col-sm-4">
            <h2><span className="number">Windows</span> release.</h2>
            <p>We are currently in the proces of creating an installer, currently only the portable version is
                available. To install and get started:</p>
            <ol>
              <li>Download the zip below.</li>
              <li>Run livekeys.exe</li>
              <li>
                For a quick start, use the open button <img src="/img/doc-open.png" alt="Open Icon" /> to load a sample from the samples directory.
              </li>
            </ol>
            <hr />
            <ul className="download">
              <li>
                <a href="https://github.com/live-keys/livekeys/releases/download/v1.9.0/livekeys-1.9.0-msvc2019-64.zip">Livekeys Windows v1.9.0</a>
              </li>
            </ul>
          </div>
          <div className="col-sm-4">
            <h2><span className="number">Linux</span> release.</h2>
            <p>
              There are 2 types of releases for linux:
            </p>
            <ul>
              <li>Standalone with all required opencv and qt dependencies</li>
              <li>Minimal release which expects qt and open cv already installed on the system.</li>
            </ul>
            <p>To install:</p>
            <ol>
              <li>Download the archive below.</li>
              <li>Extract the archive: <br /><code>tar -xf livekeys*.tar.gz</code></li>
              <li>Start livekeys <br /><code>./livekeys</code></li>
            </ol>

            <hr />

            <ul className="download">
              <li>
                <a href="https://github.com/live-keys/livekeys/releases/download/v1.9.0/livekeys-1.9.0-gcc-64-standalone.tar.gz">Livekeys Linux v1.9.0 Standalone</a>
              </li>
              <li>
                <a href="https://github.com/live-keys/livekeys/releases/download/v1.9.0/livekeys-1.9.0-gcc-64.tar.gz">Livekeys Linux v1.9.0 Without Dependencies</a>
              </li>
            </ul>

          </div>
          <div className="col-sm-4">
            <h2><span className="number">MacOS</span> release.</h2>
            <p>
              To install:
            </p>
            <ol>
              <li>Download the dmg file below.</li>
              <li>Open the file and drag livekeys to the Application forlder</li>
              <li>Note: When opening Livekeys for the first time, make sure to first right click on the application, click <code>Open</code> and after, click <code>Open</code> again. This will allow Livekeys
              to be opened from then on.</li>
            </ol>

            <hr />

            <ul className="download">
              <li><a href="https://github.com/live-keys/livekeys/releases/download/v1.9.0/Livekeys.1.9.0.dmg">Livekeys MacOS v1.9.0</a></li>
            </ul>
          </div>
        </div>

        <h1>Compiling from source</h1>
        <div className="row">
          <div className="col-sm-12">
            <p>
              To compile, you need Open CV (version 4.*) and Qt (version 5.13 or higher) installed. On windows, <code>OPENCV_DIR</code> environment variable must be set up.
            </p>
            <p>
              You can get the latest source from github:
              <code>git clone https://github.com/live-keys/livekeys.git</code>
            </p>
            <p>
              The build directory can be used to compile: <br />
              <pre className="code">cd build
    qmake -r
    make (or nmake)</pre>

            </p>
          </div>
        </div>

        <div className="row">
          <h1 id="release-notes">Release notes for 1.9</h1>
          <div className="col-sm-12">
            <ul>
              <li>Added vector editor</li>
              <li>Added layout files</li>
              <li>Added Support for read only properties</li>
              <li>Added Support for chain properties</li>
              <li>Added new color picker</li>
              <li>Fully Integrated Builders and Watchers</li>
              <li>Support for Drawing Gradients</li>
              <li>Support for connecting to singletons</li>
            </ul>
          </div>
        </div>

        <div className="row">
          <h1>Older versions.</h1>
          <div className="col-sm-12">
            <p>
              For a list of older versions, check out the <a href="https://github.com/live-keys/livekeys/releases">github releases</a>.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Download