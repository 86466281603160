import React, { Component } from "react"
import $ from 'jquery'

class Footer extends Component {

  componentDidMount() {

    // github link

    var timeLeft = 3, timeInterval = null
    $(`.github-link`).click(function () {
      $(`.github-redirect`).slideDown(500).find(`.github-redirect-info`).html(`Redirecting you to github in ... ` + eval(timeLeft) + ` seconds.`)
      timeInterval = window.setInterval(function () {
        if (eval(timeLeft) === 1) {
          window.location = ($(`.github-link`).attr(`href`))
        } else {
          timeLeft--
          $(`.github-redirect`).find(`.github-redirect-info`).html(`Redirecting you to github in ... ` + eval(timeLeft) + ` seconds.`)
        }
      }, 1000)

      return false
    })
    $(`.github-redirect-cancel a`).click(function () {
      if (timeInterval !== null) {
        clearInterval(timeInterval)
      }
      timeLeft = 4
      $(`.github-redirect`).slideUp(200)
      return false
    })

    // jQuery to collapse the navbar on scroll
    function collapseNavbar() {
      if ($(`.navbar`).offset().top > 50) {
        $(`.navbar.fixed-top`).addClass(`top-nav-collapse`)
      } else {
        $(`.navbar.fixed-top`).removeClass(`top-nav-collapse`)
      }
    }

    $(window).scroll(collapseNavbar)
    $(document).ready(collapseNavbar)
  }

  render() {
    return (
      <footer>
        <div className="container text-center">
          <div className="row">
            <div className="col-sm-9">
              <section className="copyright">
                <p>Project and copyright &copy; 2016 - 2020</p>
              </section>
              <ul className="list-inline">
                <li className="list-inline-item"><a href="https://twitter.com/dinu_sv"><img src="/img/twitter-icon-top-big.png" alt="follow-twitter-link" /></a></li>
                <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCfNA1KQqyJ_nZV64wrLc_-w"><img src="/img/youtube-icon-top-big.png" alt="follow-twitter-link" /></a></li>
                <li className="list-inline-item"><a href="https://github.com/livekeys"><img src="/img/footer-icon-github.png" alt="follow-github-link" /></a></li>
              </ul>
            </div>
            <div class="col-sm-3">
              <section class="copyright design">
                <p>
                  Design by
                  <a href="http://dinusv.com">
                    <img src="img/footer-logo.png" alt="dinusv link" />
                  </a>
                </p>
              </section>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer