import axios from 'axios'
// const { PackagePrivilegesTemplate, OrganizationPrivilegesTemplate, UserPrivilegesTemplate, Privileges } = require(`./privilegesTemplate`)
import {
  PackagePrivilegesTemplate,
  OrganizationPrivilegesTemplate,
  Privileges
} from './privilegesTemplate'


const api = axios.create({
  baseURL: `/forum/web-pm-api/`,
  headers: {
    "Content-Type": `application/json`,
  }
})

const GetOrgs = async () => {
  try {
    const response = await api(`organization`)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

const GetOrganization = async id => {
  try {
    const response = await api(`organization/${id}`)
    return response.data.data
  } catch (error) {
    throw error
  }
}

const GetPackages = async query => {
  try {
    query.search = query.q ? query.q : ''
    delete query.q

    const response = await api(`package`, {
      params: query
    })

    return response.data.data
  } catch (error) {
    console.error(error)
  }
}

const GetPackagesByUser = async id => {
  try {
    const response = await api(`user/${id}/package`)

    return response.data.data
  } catch (error) {
    console.error(error)
  }
}

const GetPackagesByOrg = async id => {
  try {
    const response = await api(`organization/${id}/package`)

    return response.data.data
  } catch (error) {
    console.error(error)
  }
}

const GetPackage = async id => {
  try {
    const response = await api(`package/${id}`)

    return response.data.data
  } catch (error) {
    console.error(error)
  }
}

const GetPackageByName = async name => {
  try {
    const response = await api(`package-by-name/${name}`)
    return response.data.data
  } catch (error) {
    throw new Error(`Package does not exist`)
  }
}

const PostPackage = async formData => {
  try {
    const response = await api.post(`package/`, formData)
    return response.data.data
  } catch (error) {
    throw error
  }
}


const PostPackageForOrg = async (orgId, formData) => {
  try {
    const response = await api.post(`organization/${orgId}/package`, formData)

    return response.data.data
  } catch (error) {
    throw error
  }
}

const PutPackage = async (id, formData) => {
  try {
    const response = await api.put(`package/${id}`, formData)

    return response.data.data
  } catch (error) {
    throw error
  }
}

const GetVersionById = async id => {
  try {
    const response = await api(`packageversion/${id}`)
    return response.data.data
  } catch (error) {
    console.error(error)
  }
}

const GetVersionByName = async (name, versions) => {
  let version = versions.find(pkg => pkg.version === name)
  if (!version) {
    return undefined
  }

  let id = version._id

  try {
    const response = await api(`packageversion/${id}`)
    return response.data.data
  } catch (error) {
    console.error(error)
  }
}

/// Returns only the versions, without their releases
const GetPackageVersions = async id => {
  try {
    const response = await api(`package/${id}/packageversions`)
    return response.data.data
  } catch (error) {
    console.error(error)
  }
}

const GetVersionReleases = async (id, version) => {
  try {
    const response = await api(`package/${id}/versionreleases/${version}`)
    return response.data.data
  } catch (error) {
    console.error(error)
  }
}

const GetReleaseReferences = async (id) => {
  try {
    const response = await api(`packagereleasereferences/${id}`)
    return response.data.data
  } catch (error) {
    console.error(error)
  }
}


const GetPrivilegesForPackage = async (user, p) => {
  var privileges = new Privileges(PackagePrivilegesTemplate)

  if (user && user.isAdmin) {
    return privileges.promote([`canDelete`, `canWrite`, `canRelease`])
  }

  // return privileges; //Return here for admin access only

  let isOwner = user && p.user ? user.username === p.user.username : false
  if (isOwner) {
    return privileges.promote([`canDelete`, `canWrite`, `canRelease`])
  }

  if (p.team) {
    let teamData = p.team

    for (const uid of teamData.users) {
      if (uid === user._id) {
        return privileges.promote([`canDelete`, `canWrite`, `canRelease`])
      }
    }
  }

  if (p.organization) {
    let organizationData = await GetOrganization(p.organization)

    if (organizationData) {
      for (const uid of organizationData.admins) {
        if (uid === user._id)
          return privileges.promote([`canDelete`, `canWrite`, `canRelease`])
      }
      if (organizationData.owner === user._id) {
        return privileges.promote([`canDelete`, `canWrite`, `canRelease`])
      }
    }
  }

  return privileges
}

const GetPrivilegesForUser = (user, userPageName) => {
  if (user && user.isAdmin)
    return { canCreatePackage: user && user.username === userPageName }
}

const GetPrivilegesForOrganization = (user, org) => {
  var privileges = new Privileges(OrganizationPrivilegesTemplate)

  if (user && user.isAdmin) {
    return privileges.promote([
      `canDelete`,
      `canUpdate`,
      `canAddTeam`,
      `canUpdateTeam`,
      `canRemoveTeam`,
      `canAddPackage`,
      `canAssignPackage`,
      `canRemovePackage`,
      `canInviteUser`,
      `canRemoveUser`,
      `canPromoteUser`
    ])
  }

  //return privileges; //Return here for admin access only

  if (org && user) {
    for (const uid of org.admins) {
      if (uid === user._id) {
        return privileges.promote([
          `canAddPackage`,
          `canAssignPackage`,
          `canRemovePackage`,
          `canInviteUser`
        ])
      }
    }
    if (org.owner === user._id) {
      return privileges.promote([
        `canDelete`,
        `canUpdate`,
        `canAddTeam`,
        `canUpdateTeam`,
        `canRemoveTeam`,
        `canAddPackage`,
        `canAssignPackage`,
        `canRemovePackage`,
        `canInviteUser`,
        `canRemoveUser`,
        `canPromoteUser`
      ])
    }
  }

  return privileges
}

const DeletePackageVersion = async id => {
  try {
    const response = await api.delete(`packageversion/${id}`)
    return response
  } catch (error) {
    console.error(error)
  }
}

const DeleteTeam = async teamId => {
  try {
    const response = await api.delete(`team/${teamId}`)
    return response
  } catch (error) {
    console.error(error)
  }
}

const DeleteUserPackage = async id => {
  try {
    const response = await api.delete(`package/${id}`)
    return response
  } catch (error) {
    console.error(error)
  }
}

const DeleteUserFromTeam = async (teamId, userId) => {
  try {
    const response = await api.delete(`team/${teamId}/user/${userId}`)
    return response
  } catch (error) {
    console.error(error)
  }
}

const GetNodeBBUserByUsername = async username => {
  try {
    const response = await axios(`/forum/api/user/username/${username}`)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

const CurrentUser = async () => {
  try {
    const response = await api(`user/logged`)
    return response.data.data
  } catch (error) {
    console.error(error)
  }
}

const GetUser = async ({ id, name }) => {
  try {
    let params = {}
    if (id)
      params[`id`] = id
    else if (name)
      params[`username`] = name
    else
      return null

    const response = await api(`user`, {
      params
    })

    return response.data.data
  } catch (error) {
    throw error;
  }
}

const DeleteTeamMember = async ({ teamId, userId }) => {
  try {
    const response = await api.delete(`team/${teamId}/user/${userId}`)
    return response
  } catch (error) {
    console.error(error)
  }
}

const InviteTeamMember = async (teamId, userId) => {
  try {
    const response = await api.post(`team/${teamId}/user/${userId}`)
    return response
  } catch (error) {
    console.error(error)
  }
}

const InviteOrganizationMember = async (orgId, userId) => {
  try {
    const response = await api.post(`organization/${orgId}/user/${userId}`)
    return response
  } catch (error) {
    throw error
  }
}

const RemoveOrganizationMember = async (orgId, userId) => {
  try {
    const response = await api.delete(`organization/${orgId}/user/${userId}`)
    return response
  } catch (error) {
    throw error
  }
}

const InviteOrganizationAdmin = async (orgId, userId) => {
  try {
    const response = await api.post(`organization/${orgId}/admin/${userId}`)
    return response
  } catch (error) {
    throw error
  }
}

const RemoveOrganizationAdmin = async (orgId, userId) => {
  try {
    const response = await api.delete(`organization/${orgId}/admin/${userId}`)
    return response
  } catch (error) {
    throw error
  }
}


const PostVersion = async formData => { // old end-point
  try {
    const response = await api.post(`packageversion`, formData)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

const PostPackageRelease = async formData => { // new end-point
  try {
    const response = await api.post(`packagerelease`, formData)
    return response.data
  } catch (error) {
    throw error
  }
}

const PostOrganization = async formData => {
  try {
    const response = await api.post(`organization`, formData)

    return response.data
  } catch (error) {
    throw error
  }
}

const DeleteOrganization = async id => {
  try {
    const response = await api.delete(`organization/${id}`)

    return response.data
  } catch (error) {
    throw error
  }
}

const GetOrganizationTeams = async id => {
  try {
    const response = await api(`organization/${id}/team`)
    return response.data.data
  } catch (error) {
    throw error
  }
}

const PostTeam = async formData => {
  try {
    const response = await api.post(`team`, formData)

    return response.data
  } catch (error) {
    throw error
  }
}

const GetTeam = async id => {
  try {
    const response = await api(`team/${id}`)
    return response.data.data
  } catch (error) {
    throw error
  }
}

const AsignPackageToTeam = async (pkg, team) => {
  try {
    const response = await api.post(`team/${team}/package/${pkg}`)
    return response.data.data
  } catch (error) {
    console.error(error)
  }
}

const GetOrganizationByName = async name => {
  try {
    const response = await api(`organization-by-name/${name}`)
    return response.data.data
  } catch (error) {
    throw error
  }
}

const PostReadme = async (packageId, version, filebuffer) => {
  try {
    const formData = new FormData();
    formData.append("readme", filebuffer, { filename: 'readme.md' });
    const request_config = {
      method: "post",
      url: `/package/${packageId}/readme/${version}`,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: formData
    };

    const response = await api(request_config)

    return response.data.data
  } catch (error) {
    throw error;
  }
}

const GetReadme = async (packageName, version) => {
  try {
    const response = await api(`package/${packageName}/readme/${version}`, )
    return response.data
  } catch (error) {
    throw error
  }
}

const PostOrganizationPhoto = async (organizationId, filebuffer) => {
  try {
    const formData = new FormData();
    formData.append("picture", filebuffer, { filename: 'photo.png' });
    const request_config = {
      method: "post",
      url: `/organization/${organizationId}/picture`,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: formData
    };

    const response = await api(request_config)

    return response.data.data
  } catch (error) {
    throw error;
  }
}


export {
  CurrentUser,
  GetNodeBBUserByUsername,
  GetOrgs,
  GetOrganization,
  GetPackages,
  GetPackage,
  GetPrivilegesForPackage,
  PostPackage,
  PostPackageForOrg,
  PutPackage,
  DeleteUserPackage,
  GetPackageVersions,
  GetVersionReleases,
  GetReleaseReferences,
  GetPackagesByUser,
  GetPackagesByOrg,
  PostVersion,
  PostPackageRelease,
  GetUser,
  GetVersionById,
  GetVersionByName,
  DeletePackageVersion,
  PostOrganization,
  DeleteOrganization,
  GetOrganizationTeams,
  GetTeam,
  PostTeam,
  DeleteTeam,
  AsignPackageToTeam,
  DeleteTeamMember,
  GetPackageByName,
  GetOrganizationByName,
  GetPrivilegesForUser,
  GetPrivilegesForOrganization,
  DeleteUserFromTeam,
  InviteTeamMember,
  InviteOrganizationMember,
  RemoveOrganizationMember,
  InviteOrganizationAdmin,
  RemoveOrganizationAdmin,
  PostReadme,
  GetReadme,
  PostOrganizationPhoto
}
